<template>
  <v-main class="login">
    <div class="px-3 mt-10">
      <ValidationObserver ref="observer">
        <v-row>
          <v-col cols="12" sm="6" class="pb-0 mx-auto">
            <p class="subtitle-2 font-weight-black ma-0">メールアドレス</p>
            <ValidationProvider v-slot="{ errors }" name="メールアドレス" rules="required|email|max:254">
              <v-text-field v-model="mail" label single-line outlined dense :error-messages="errors" required  @change="inputEmail"></v-text-field>
            </ValidationProvider>
          </v-col>
        </v-row>
      </ValidationObserver>

      <div class="text-center">
        <v-col cols="12" sm="6" class="mx-auto">
          <v-btn rounded class="maincolor-bg default_button" @click="register_email()">登録</v-btn>
        </v-col>
      </div>

      <v-divider class="my-5"></v-divider>

      <div class="text-center">
        <v-row class="caption" justify="center">
          <router-link to="/commune/login">既にアカウントがある場合</router-link>
        </v-row>
      </div>

      <div class="text-center caption pt-10">
        <p class="">
          本アプリをご利用の際には、
          <router-link to="/info/rule">利用規約</router-link>
          をご確認ください。
        </p>
        <p class="caption" v-if="inquiry_mail">
          <v-icon>far fa-envelope</v-icon>
          <a :href="'mailto:'+inquiry_mail">お問い合わせ</a>
        </p>
      </div>
    </div>

    <v-overlay :value="loading">
      <v-progress-circular :size="80" :width="2" class="maincolor" indeterminate></v-progress-circular>
    </v-overlay>
  </v-main>
</template>

<script>
import { required, email, max, min } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import { DIALOG_TYPE, IS_REGIST } from '../../utils/const';
setInteractionMode('eager')
extend('required', {
  ...required,
  message: '{_field_} は必須項目です。',
})

extend('email', {
  ...email,
  message: '正しいメールアドレス形式ではありません',
})

extend('max', {
  ...max,
  message: '{length}文字以内で入力してください。',
})

extend('min', {
  ...min,
  message: '{length}文字以上で入力してください。',
})

export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      mail: null,

      loading: false,
      show: false,
      inquiry_mail: null,
    };
  },
  async created () {
    this.inquiry_mail = process.env.VUE_APP_INQUIRY_MAIL
  },
  mounted () {
    const json = this.storageSessionGet('*');
    if( !json ) {
      this.storageSessionSave(JSON.stringify({}));
    } else {
      const email = this.storageSessionGet("input_email");
      if(email) {
        this.mail = email;
      }
    }
  },
  methods: {
    register_email: function(){
      this.$refs.observer.validate().then(async result => {
        if (!result) {
            return;
        }
        try {
          //loadingを表示
          this.loading = true;

          const req = {
            'mail': this.mail,
          }
          // 会員存在確認APIの呼出
          const res = await this.apiCallCustomerPF('/commune/user/exists', req);
          if (res.exists_user) {
            this.callDialog('', '入力されたメールアドレスは既に登録されています。ログインしてください。', DIALOG_TYPE.GO_TO_COMMUNE_LOGIN);
            return;
          }

          // 新規会員登録ページに遷移
          const callback = encodeURIComponent(process.env.VUE_APP_BASE_URL + '/commune/login?regist_status=' + IS_REGIST.YES);
          this.showEcPage('/omni_member_add.html', 'callback=' + callback + '&email=' + encodeURIComponent(this.mail));
          this.loading = false // showEcPageでエラーとなった場合でもloadingが終わるよう追加

        } catch(e) {
            this.loading = false
            this.callDialog('認証エラー', '時間をおいてリトライしてください。', DIALOG_TYPE.OK);
        }
      });
    },
    async inputEmail(e){
      this.storageSessionSave(JSON.stringify({
        "input_email"  : e ,
      }));
    }
  }
};
</script>

<style scoped>
.fontsize12>>>label {
  font-size: 12px;
}

a {
  color: #333333;
}
</style>
